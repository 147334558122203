import React, {useCallback, useEffect, useState, useRef} from 'react';
import { StatusBar } from 'expo-status-bar';
import HomeScreeNavigator from './route/HomeStack';
import { Provider } from 'react-redux';
import { store } from './toolkit/store';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import WebStackNavigator from './webpages/webroute/webroute';
import weblinking from './webpages/webroute/weblinking';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';


Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig.extra.eas.projectId,
    })).data;
  } else {
    alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }
  return token;
}


SplashScreen.preventAutoHideAsync();



export default function App() {

  const [expoPushToken, setExpoPushToken] = React.useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    registerForPushNotificationsAsync().then(token => setExpoPushToken(token));

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);


  const [fontsLoaded] = useFonts({
    'Montserrat-Regular': require('./constants/fonts/Montserrat-Regular.ttf'),
    'Montserrat-SemiBold': require('./constants/fonts/Montserrat-SemiBold.ttf'),
    'Montserrat-Thin': require('./constants/fonts/Montserrat-Thin.ttf'),
    'Montserrat-Bold': require('./constants/fonts/Montserrat-Bold.ttf'),
});


useEffect(() => {
  function hdd() {
    if (fontsLoaded) {
      setTimeout(async() => {
        await SplashScreen.hideAsync();
      }, 2000);
    }
  }
  hdd()
},[fontsLoaded])

const onLayoutRootView = useCallback(async () => {
  if (fontsLoaded) {
    await SplashScreen.hideAsync();
  }
}, [fontsLoaded]);

if (!fontsLoaded) {
  return null;
}
  return (
    <Provider store={store}>

        {
          Platform.OS == 'web' ? 
          <NavigationContainer linking={weblinking}>
          <WebStackNavigator />
        </NavigationContainer>
        :
        <NavigationContainer>
          <HomeScreeNavigator />
        </NavigationContainer>
        }

    </Provider>
  );
}
