import React, { useState, useCallback } from "react";
import { View, Text, StyleSheet, useWindowDimensions, ScrollView, TouchableOpacity, Image } from "react-native";
import CustomSafeArea from "../../components/CustomSfview";
import { useSelector } from "react-redux";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import  Icon  from "react-native-vector-icons/AntDesign";
import  Icon2  from "react-native-vector-icons/Feather";
import HeaderComponent from "../../components/Header";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import ServerData from "../../constants/other/serverData";



const walletcdata = [
    {
        'id':2,
        'title':'Send Gibnu Points',
        'icon':'corner-up-right',
        'route':'sendCashBackScreen'
    },
    {
        'id':1,
        'title':'Withdraw Cashback',
        'icon':'download',
        'route':'Withdraw',
    },
    {
        'id':3,
        'title':'Change Wallet PIN',
        'icon':'settings',
        'route':'ChangeWalletPin'
    },
    {
        'id':4,
        'title':'Receive Points',
        'icon':'repeat',
        'route':'PointExchange'
    },

]


const OnlinePayment = () => {
    const navigation = useNavigation()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [walletBalance, setWalletBalance] = useState(null)
    const [walletbalanceD, setWalletBalanceD] = useState(null)
    const [holdBalance, setHoldBalance] = useState(null)
    const [loading, setLoading] = useState(false)
    const [transactionList, setTransactionList] = useState([])
    const [walletid, setWalledId] = useState(null)

    useFocusEffect(
        useCallback(() => {
            axios.get(`${ServerData.admin}/waalletDetails/${tknsts}/`)
                .then(function (response) {
                    if(response.data.code == 1) {
                        const walbal = response.data.data.balance.toFixed(2).split('.')
                        setWalletBalance(walbal[0])
                        setWalletBalanceD(walbal[1])
                        setHoldBalance(response.data.data.onhold)
                        setWalledId(response.data.data.walletid)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                });
        },[])
    )

    useFocusEffect(
        useCallback(() => {
            axios.get(`${ServerData.admin}/WalletTransactionlist/${tknsts}/`)
                .then(function (response) {
                    setTransactionList(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                });
        },[])
    )



    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background
        },
        sfview:{
            flex:1,
        },
        scrl:{
            backgroundColor:appColor.scrollgray
        },
        tp:{
            padding:10,
            backgroundColor:appColor.background
        },
        txt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:16
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginTop:10
        },
        txt3:{
            fontFamily:appfont.regular,
            color:appColor.txheadblack,
            fontSize:30, 
        },
        rr:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            height:40,
            borderBottomWidth:0.5,
            borderBottomColor:appColor.gray,
            marginTop:10
        },
        txt4:{
            fontFamily:appfont.semibold,
            color:appColor.primary,
            fontSize:16,
            marginLeft:10
        },
        l1:{
            height:5,
            backgroundColor:appColor.primary
        },
        l2:{
            height:5,
            backgroundColor:appColor.txheadblack,
            opacity:0.8
        },
        rc:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            height:70,
            backgroundColor:appColor.background,
            padding:10
        },
        fltr:{
            fontFamily:appfont.regular,
            color:appColor.txheadblack,
            fontSize:14,
            marginLeft:5
        },
        tsv:{
            height:90,
            borderBottomWidth:0.5,
            borderBottomColor:appColor.gray,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            padding:10
        },
        cl:{
            height:40,
            width:40,
            marginRight:10,
            justifyContent:'center',
            alignItems:'center'
        },
        rr2:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            marginTop:5,
            marginBottom:5
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt6:{
            fontFamily:appfont.semibold,
            fontSize:16,
            color:appColor.red
        },
        txt10:{
            fontFamily:appfont.semibold,
            fontSize:16,
            color:appColor.green
        },
        txt7:{
            fontFamily:appfont.regular,
            fontSize:10,
            color:appColor.txheadblack
        },
        tp1:{
            flexDirection:'row',
            justifyContent:'space-between'
        },
        txt9:{
            fontFamily:appfont.semibold,
            color:appColor.red,
            fontSize:14
        },
        rth:{
            alignItems:'flex-end',
        },
        cricon:{
            height:30,
            width:30
        }
    })

    return(
        <CustomSafeArea loading={loading}>
            <HeaderComponent title={'Wallet'} hidect={true}/>
            <ScrollView style={styles.scrl}>
                <View style={styles.tp}>
                <View style={styles.tp1}>
                    <View>
                        <Text style={styles.txt1}>Gibnu Points</Text>
                        <Text style={styles.txt2}><Text style={styles.txt3}></Text> {walletBalance}<Text style={styles.txt3}>.{walletbalanceD}</Text></Text>
                    </View>
                    <View style={styles.rth}>
                        <Text style={styles.txt1}>On Hold</Text>
                        <Text style={styles.txt9}><Text style={styles.txt9}></Text> {holdBalance}</Text>
                    </View>
                </View>
                    <View>
                        {
                            walletcdata.map((item) => {
                                return(
                                    <TouchableOpacity key={item.id} style={styles.rr} onPress={() => navigation.navigate(item.route, {type:1})}>
                                        <View style={{flexDirection:'row', alignItems:'center'}}>
                                            <Icon2 name={item.icon} size={20} color={appColor.txgray} />
                                            <Text style={styles.txt4}>{item.title}</Text>
                                        </View>
                                        <Icon name="right" size={14} color={appColor.txheadblack}/>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>
                </View>
                <View style={styles.l1}></View>
                <View style={styles.l2}></View>
                <View style={styles.rc}>
                    <Text style={styles.txt1}>Recents</Text>
                    <TouchableOpacity style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <Icon2 name="filter" size={14} color={appColor.txheadblack}/>
                        <Text style={styles.fltr}>Filter</Text>
                    </TouchableOpacity>
                </View>
                <View style={{marginTop:10, backgroundColor:appColor.background}}>
                {
                    transactionList.map((item) => {
                        return(
                            <TouchableOpacity key={item.id} style={styles.tsv}>
                                <View style={styles.cl}>
                                    <Image source={item.sender == walletid ? require('./icons/debit.png') : require('./icons/credit.png')} style={styles.cricon}/>
                                </View>
                                <View style={{flex:1}}>
                                    <View style={styles.rr2}>
                                    <Text style={styles.txt5}>{item.transfer ? item.transfer.user.first_name : null} {item.transfer ? item.transfer.user.last_name : null} <Text style={{fontFamily:appfont.regular}}>TID:{item.id}</Text></Text>
                                        <Text style={item.sender == walletid ? styles.txt6 : styles.txt10}>{item.sender == walletid ? '-':null}{item.amount}</Text>
                                    </View>
                                    <View style={styles.rr2}>
                                        <Text style={styles.txt7}>{item.datetime.split("T")[0]} {item.datetime.split("T")[1].substring(0, 5)}</Text>
                                        <Text style={styles.txt7}>{item.sender == walletid ? 'Debit' : 'Credit'}</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        )
                    })
                }
                </View>

                </ScrollView>
        </CustomSafeArea>
    )
}

export default OnlinePayment