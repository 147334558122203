import React, {useState, useEffect} from "react";
import { View, StyleSheet, useWindowDimensions, Text, TouchableOpacity, Linking } from "react-native";
import CustomSafeArea from "../../components/CustomSfview";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";
import { useDispatch } from "react-redux";
import { userToken } from "../../toolkit/slice";
import axios from "axios";
import ServerData from "../../constants/other/serverData";
import * as SecureStore from 'expo-secure-store';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';


const LoginScreen = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    const [token, setToken] = useState(null)
    const [devicetoken, setDeviceToken] = useState('')

    useEffect(() => {
        async function registerForPushNotificationsAsync() {
            let token;
            if (Device.isDevice) {
              const { status: existingStatus } = await Notifications.getPermissionsAsync();
              let finalStatus = existingStatus;
              if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
              }
              if (finalStatus !== 'granted') {
                alert('Failed to get push token for push notification!');
                return;
              }
              token = (await Notifications.getExpoPushTokenAsync({
                projectId: Constants.expoConfig.extra.eas.projectId,
              })).data;
              setDeviceToken(token)
            } else {
              alert('Must use physical device for Push Notifications');
            }
          
            if (Platform.OS === 'android') {
              Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
              });
            }
          
            return token;
          }
          registerForPushNotificationsAsync()
    },[])




    useEffect(() => {
        if (token !== null) {
            async function saveToken() {
                await SecureStore.setItemAsync('token', token);
                dispatch(userToken(token))
            }
            saveToken()
        }
    }, [token])

    function handleSignIn() {
        if(mobile == '' || password == '') {
            alert('Please enter valid credentials')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.uri3}/agentsignin/`, {
                username:mobile,
                password:password,
                pushtoken:devicetoken,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1) {
                        setToken(response.data.token)
                    }else{
                        alert(response.data.message)
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert(error)
                    console.log(error.data)
                });
        }
    }

    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10
        },
        tp:{
            marginLeft:24,
            marginRight:24
        },
        tp2:{
            alignItems:'center'
        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
    })
    return(
        <CustomSafeArea>
            <View style={styles.tp}>
                <Text style={styles.txt}>Sign In</Text>
                <Text style={styles.txt2}>Welcome Back!</Text>
            </View>
            <View style={styles.tp2}>
                <CustomTextInput title={'Mobile Number'} wd={width-48} onchange={setMobile}/>
                <CustomTextInput title={'Password'} wd={width-48} st={true} onchange={setPassword}/>
                <TouchableOpacity style={{alignSelf:'flex-end', marginRight:24}} onPress={() => navigation.navigate('ForgotPassword')}>
                    <Text style={styles.txt5}>Forgot Password?</Text>
                </TouchableOpacity>
                <View style={{marginTop:20}}>
                <CustomButton title={'Login'} bg={appColor.primary} tc={appColor.background} ht={55} wt={width-48} onpress={() => handleSignIn()} ai={modalVisible}/>
                </View>
                    <View style={styles.snv}>
                        <Text style={styles.txt1}>Don't have an account</Text>
                        <TouchableOpacity onPress={() => Linking.openURL('https://join.gibnu.com/')}>
                            <Text style={styles.txt5}> Sign Up</Text>
                        </TouchableOpacity>
                    </View>
            </View>
        </CustomSafeArea>
    )
}

export default LoginScreen