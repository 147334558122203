import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions, Linking } from 'react-native'
import React, { useState, useEffect } from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import Splash1Comp from './Splash1Comp'
import CustomButton from '../../components/customButton'
import Icon from 'react-native-vector-icons/AntDesign'
import appColor from '../../constants/other/colors'
import { LinearGradient } from 'expo-linear-gradient';
import Splash2Comp from './Splash2Comp'
import { useNavigation } from '@react-navigation/native'


const SplashScreen = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [splashStage, setSplashStage] = useState(1)

    useEffect(() => {
        const handleDeepLink = async (event) => {
          const { url } = event;
          // Process the URL here (e.g., extract data from it, if needed)
          const referralIdRegex = /referral_id=(\d+)/;
          const referralIdMatch = url.match(referralIdRegex);
    
          if (referralIdMatch) {
            const referralId = referralIdMatch[1];
            // Do something with the referralId, e.g., store it in app state or perform a referral-related action
            // In this example, we'll navigate to the SignupScreen and pass the referralId as a parameter
            navigation.navigate('Signup', { referalid: referralId });
        }
        };
    
        // Check if the app was opened via a deep link
        Linking.getInitialURL().then((url) => {
          if (url) {
            handleDeepLink({ url });
          }
        });
    
        // Add a listener to handle incoming deep links while the app is open
        const handleUrl = (event) => {
          handleDeepLink(event);
        };
    
        Linking.addEventListener('url', handleUrl);
      }, []);

    const styles = StyleSheet.create({
        inm:{
            flex:1,
            justifyContent:'space-between',
            alignItems:'center'
        },
        tch:{
            height:80,
            width:80,
            backgroundColor:appColor.primary,
            justifyContent:"center",
            alignItems:'center',
            borderRadius:40
        }
    })
    
  return (
    <CustomSafeArea>
        <View style={styles.inm}>
          {splashStage === 1 ? <Splash1Comp /> : <Splash2Comp />}
            <View>
            {
                splashStage == 1 ?
            
                <TouchableOpacity style={styles.tch} onPress={() => setSplashStage(2)}>
                    <Icon name='right' size={25} color={appColor.background}/>
                </TouchableOpacity>
                :
                <View>
                    <CustomButton title={'Login'} onpress={() => navigation.navigate('Login')}/>
                    <CustomButton title={'Registration'} mt={10} bg={appColor.primary} tc={appColor.background} onpress={() => Linking.openURL('https://join.gibnu.com/')}/>
                </View>
            }
            </View>
        </View>
    </CustomSafeArea>
  )
}

export default SplashScreen