import { View, Text, StyleSheet, useWindowDimensions, ScrollView, TouchableOpacity } from 'react-native'
import React, {useEffect, useState} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import CustomTextInput from '../../components/customTextInput'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import ServerData from '../../constants/other/serverData'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Icon from 'react-native-vector-icons/Feather'


const BankAccount = () => {
  const tknsts = useSelector((state) => state.appredux.userToken)
  const {width} = useWindowDimensions()
  const [modalVisible, setModalVisible] = useState(false)
  const [acname, setAcName] = useState('')
  const [acnumber, setAcNumber] = useState('')
  const [cnfacnumber, setCNFacnumber] = useState('')
  const [ifsccode, setIfscCode] = useState('')
  const [ifscverify, setIfscVerify] = useState(false)
  const [ifscbankdetails, setIFSCBankDetails] = useState('')
  const [upiverifieddata, setUpiVerifiedData] = useState(false)

  function changeIFSCVErify(){
    setIfscVerify(false)
    setIFSCBankDetails('')
}

  function validateIFSC(){
    if(ifsccode.length !== 11){
        alert('Invalid IFSC Code')
    }else{
        setModalVisible(true)
        axios.post(`${ServerData.uri3}/initiatecreditcardbillpayment/`, {
            ifsc:ifsccode,
            stage:2,
            })
            .then(function (response) {
                setModalVisible(false)
                if(response.data.data.code == 1){
                    setIfscVerify(1)
                    setIFSCBankDetails(response.data.data.bank)
                }else{
                    alert('Invalid IFSC')
                }
            })
            .catch(function (error) {
                setModalVisible(false)
                alert(error)
            });
    }
}

  useEffect(() => {
    function getBankAccountDetails(){
        setModalVisible(true)
      axios.post(`${ServerData.uri3}/updatebankdetails/`, {
        token:tknsts,
        stage:1,
      })
      .then(response => {
        setModalVisible(false)
        if(response.data.code == 1){
            setAcName(response.data.data.acname)
            setAcNumber(response.data.data.acnumber)
            setCNFacnumber(response.data.data.acnumber)
            setIfscCode(response.data.data.ifsc)
        }
      })
      .catch(error => {
        setModalVisible(false)
          console.log(error)
      });
    }
    getBankAccountDetails()
  },[])

  function bankUpdate() {
    if(acname == ''){
        alert('Please enter account name.')
    }else if(acnumber !== cnfacnumber){
        alert('Account number does not match')
    }else if(ifscverify == false){
        alert('Please verify ifsc')
    }else{
        setModalVisible(true)
        axios.post(`${ServerData.uri3}/updatebankdetails/`, {
        token:tknsts,
        stage:2,
        acname:acname,
        acnumber:acnumber,
        ifsc:ifsccode,
        })
        .then(response => {
            setModalVisible(false)
            if(response.data.code == 2){
                alert(response.data.message)
            }
        })
        .catch(error => {
        setModalVisible(false)
            console.log(error)
        });
    }
  }


  const styles = StyleSheet.create({
    txt:{
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:30,
      marginBottom:10
  },
  scrl:{
      paddingTop:20,
      marginLeft:24,
      marginRight:24,
      flex:1
  },
  txt2:{
      fontFamily:appfont.semibold,
      color:appColor.txgray,
      fontSize:12,
      marginBottom:10

  },
  snv:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      marginTop:10
  },
  txt1:{
      fontFamily:appfont.regular,
      fontSize:14,
      color:appColor.txheadblack
  },
  txt5:{
      fontFamily:appfont.semibold,
      fontSize:14,
      color:appColor.primary
  },
  slm:{
      flexDirection:'row',
      alignItems:'center',
      marginTop:10
  },
  sl:{
      height:40,
      width:(width-40)/3,
      borderRadius:10,
      borderWidth:1,
      borderColor:appColor.txgray,
      justifyContent:'center',
      alignItems:'center',
      marginRight:10
  },
  sl1:{
      height:40,
      width:(width-40)/3,
      borderRadius:10,
      borderWidth:1,
      borderColor:appColor.primary,
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:appColor.primary,
      marginRight:10
  },
  slt:{
      fontFamily:appfont.semibold,
      color:appColor.background,
      fontSize:14
  },
  slt1:{
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:14
  },
  dropdown:{
      height:50,
      width:width-48,
      borderWidth:1,
      borderColor:appColor.txgray,
      justifyContent:'center',
      alignItems:'center',
      borderRadius:10,
      paddingLeft:10,
      marginBottom:10
  },
  mv:{
      flexDirection:'row',
      alignItems:'center'
  },
  mtch1:{
      height:40,
      width:70,
      borderWidth:1,
      borderColor:appColor.primary,
      justifyContent:'center',
      alignItems:'center',
      marginRight:10,
      borderRadius:10,
      backgroundColor:appColor.primary
  },
  mtch2:{
      height:40,
      width:70,
      borderWidth:1,
      borderColor:appColor.primary,
      justifyContent:'center',
      alignItems:'center',
      marginRight:10,
      borderRadius:10
  },
  mtxt1:{
      fontFamily:appfont.semibold,
      color:appColor.background,
      fontSize:12
  },
  mtxt2:{
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:12
  },
  rr:{
      justifyContent:'center',
      alignItems:'center'
  }
  })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Add Bank Account'} hidect={true}/>
        <ScrollView style={styles.scrl} showsVerticalScrollIndicator={false}>
            <View style={{marginTop:10}}>
                <CustomTextInput wd={(width-48)} title={'Account Holder Name'} value={acname} onchange={setAcName}/>
                <CustomTextInput wd={(width-48)} title={'Account Number'} value={acnumber} onchange={setAcNumber} st={true}/>
                <CustomTextInput wd={(width-48)} title={'Confirm Account Number'} value={cnfacnumber} onchange={setCNFacnumber}/>
                <CustomTextInput wd={(width-48)} title={'IFSC Code'} value={ifsccode} onchange={setIfscCode} disable={ifscverify ? true : false}/>
                {
                        ifscverify == 1 ? 
                        <View style={{flexDirection:"row", alignSelf:'flex-end', alignItems:'center'}}>
                            <Text style={{fontSize:8, color:appColor.green, fontFamily:appfont.semibold, marginRight:20}}>{ifscbankdetails}</Text>
                            <TouchableOpacity onPress={() => changeIFSCVErify()}>
                                <Icon name='edit' size={15} color={appColor.primary} />
                            </TouchableOpacity>
                        </View>
                        :
                        <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={() => validateIFSC()}>
                            <Text style={{fontFamily:appfont.semibold, color:appColor.primary, fontSize:10, textAlign:'right'}}>Verify IFSC</Text>
                     </TouchableOpacity>
                    }
                <View style={{height:50}}></View>
                <CustomButton title={'Update'} bg={appColor.primary} tc={appColor.background} wt={width-48} ht={55} onpress={() => bankUpdate()} ai={modalVisible}/>
                <View style={{height:50}}></View>

            </View>
            </ScrollView>
    </CustomSafeArea>
  )
}

export default BankAccount