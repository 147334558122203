import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, useWindowDimensions, Platform, Image, Alert, Linking, FlatList } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import HomeSaleCard from "../../components/HomeSaleCard";
import { StatusBar } from 'expo-status-bar';
import { useNavigation } from "@react-navigation/native";
import ServerData from "../../constants/other/serverData";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { storeData } from "../../toolkit/slice";
import appversion from "../../constants/other/version";
import RepotingAgentCard from "../../components/RepotingAgentCard";
import PlatformApp from "../../components/PlatformApp";
import CustomSafeArea from "../../components/CustomSfview";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";


const HomeData = [
    {
        'id':1,
        'name':'Loan Verifications',
        'num':0,
        'route':'loanapplns',
    },
    {
        'id':2,
        'name':'Pending Approvals',
        'num':'0',
        'route':'agntchange',
    },
    {
        'id':3,
        'name':'Complaints',
        'num':'0',
        'route':'mktsales',
    },
    {
        'id':4,
        'name':'Earning',
        'num':'0',
        'route':'earnings',
    },
]

const AgentData = [
    {
        'id':1,
        'name':'Shop Verification',
        'num':0,
        'route':'ShopVerificationList',
    },
    {
        'id':2,
        'name':'Delivery Verification',
        'num':0,
        'route':'DeliveryScreen',
    },
]




const HomeScreen = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [actives, setActiveS] = useState(2)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const storedata = useSelector((state) => state.appredux.storeData)
    const dispatch = useDispatch()

    useEffect(() => {
        function getAppVersions(){
          axios.post(`${ServerData.uri3}/getagentappversions/`, {
            token:tknsts,
            versioncode:appversion,
          })
          .then(response => {
            if(response.data.data.status == 2){
                Alert.alert('Update Available', response.data.data.message, [
                    {
                      text: 'Update Later',
                      onPress: () => console.log('Cancel Pressed'),
                      style: 'cancel',
                    },
                    {text: 'Update', onPress: () => Linking.openURL('https://play.google.com/store/apps/details?id=com.gibnuagent.in')},
                  ]);
            }else if(response.data.data.status == 0){
                Alert.alert('Update Available', response.data.data.message, [
                    {text: 'Update', onPress: () => Linking.openURL('https://play.google.com/store/apps/details?id=com.gibnuagent.in')},
                  ]);
            }
          })
          .catch(error => {
              console.log(error)
          });
        }
        getAppVersions()
      },[])


    function handActive() {
        if (actives == 1) {
            setActiveS(2)
        }else {
            setActiveS(1)
        }
    }

    const renderHeader = () => {
        return(
            <>
                {
                storedata.verification == 0 ? 
                <TouchableOpacity style={styles.nvf} onPress={() => navigation.navigate('uploaddoc')}>
                    <Text style={styles.nvftxt}>Your agent id is unverified!! Please upload documents & verify.</Text>
                </TouchableOpacity>
                :
                null
            }

                    <View style={styles.sc}>
                    <View style={{marginTop:10}}>
                        <HomeSaleCard title={'Pending Shop Verification'} numb={''} onpress={() => navigation.navigate('vendorlistView', {nm:'Dukaan', usrname:tknsts, type:1, emrequest: 0})}/>
                    </View>
                    <View style={{marginTop:10}}>
                        <HomeSaleCard title={'Pending Delivery Verification'} numb={''} onpress={() => navigation.navigate('DeliveryScreen', {nm:'Delivery Boy/ Girl', usrname:tknsts, type:1, emrequest: 0})}/>
                    </View>
                    <View style={{marginTop:10}}>
                        <HomeSaleCard title={'Pending Approvals'} numb={''} onpress={() => navigation.navigate('agntchange')}/>
                    </View>
                    <View style={{marginTop:10}}>
                        <HomeSaleCard title={'Earnings'} numb={''} onpress={() => navigation.navigate('earnings')}/>
                    </View>
                    </View>
                <RepotingAgentCard mobile={storedata.agentmobile ? storedata.agentmobile : false} agentname={storedata.agentname ? storedata.agentname : false} type={1}/>
                <RepotingAgentCard mobile={storedata.superagentmobile ? storedata.superagentmobile : false} agentname={storedata.superagentname ? storedata.superagentname : false} type={2}/>
                <PlatformApp />
            </>
        )
    }

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background
        },
        sfview:{
            flex:1
        },
        tp:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:10,
            paddingRight:10,
        },
        act:{
            justifyContent:'center',
            alignItems:'center',
            paddingLeft:10,
            paddingRight:10,
            height:35,
            width:35,
            backgroundColor:appColor.background,
            borderRadius:10,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        cr:{
            height:25,
            width:25,
            borderRadius:13,
            backgroundColor:appColor.background,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:12
        },
        tx2:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:20
        },
        sc:{
            padding:10,
            flexDirection:'row',
            justifyContent:'space-between',
            flexWrap:'wrap'
        },
        txh:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:16,
            marginLeft:10,
            marginTop:10
        },
        nvf:{
            backgroundColor:'#FEA1A1',
            paddingLeft:10,
            paddingRight:10,
            paddingTop:3,
            paddingBottom:3,
            marginTop:10
        },
        nvftxt:{
            fontFamily:appfont.regular,
            color:appColor.txheadblack,
            fontSize:12
        }
    })

    return (
        <CustomSafeArea>
            <View style={styles.tp}>
                <Text style={styles.tx2}>Hi {storedata.name}</Text>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                    <TouchableOpacity style={styles.act} onPress={() => navigation.navigate('StoreID')}>
                        <Image source={require('../../constants/img/qr-code.png')} style={{height:20, width:20, resizeMode:"contain"}} />
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.act, {marginLeft:10, paddingLeft:0, paddingRight:0}]} onPress={() => navigation.navigate('NotificationPage')}>
                        <Icon name={storedata.alert ? "bell-badge-outline" : "bell-outline"} size={20} color={storedata.alert ? appColor.red : appColor.txheadblack}/>
                    </TouchableOpacity>
                </View>
            </View>
            <FlatList 
                ListHeaderComponent={renderHeader}
                showsVerticalScrollIndicator={false}
            />
        </CustomSafeArea>
    )
}

export default HomeScreen