import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions, Linking, Modal, ScrollView } from 'react-native'
import React, {useState, useEffect, useRef} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'
import { Image } from 'expo-image'
import CustomButton from '../../components/customButton'
import Icon from 'react-native-vector-icons/AntDesign'
import QRCode from 'react-native-qrcode-svg';
import * as Sharing from 'expo-sharing';
import ViewShot from "react-native-view-shot";
import UploadPhotoComponent from '../../components/UploadPhotoComponent'
import * as ImagePicker from 'expo-image-picker';
import { useSelector } from 'react-redux'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'

const instpoints = [
    {
        id:1,
        text:'Make payment using upi options or scan the QR code by clicking QR option.'
    },
    {
        id:2,
        text:'Take screenshot of payment confirmation page. The screenshot must show UTR number'
    },
    {
        id:3,
        text:'Upload screenshot below and click on confirm payment.'
    }
]


const UPIPayment = ({route, navigation}) => {
    const {paydata} = route.params;
    const {width, height} = useWindowDimensions()
    const [mvisible, setMVisible] = useState(false)
    const viewShotRef = useRef(null);
    const [paymentimg, setPaymentImg] = useState(null)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [loading, setLoading] = useState(false)


    function uploadPaymentData() {
        if(paymentimg == null) {
            alert('Please upload payment screenshot')
        } else {
            setLoading(true)
            const formData = new FormData()
            formData.append('payproof', {uri:paymentimg.uri, name:paymentimg.fileName ? paymentimg.fileName: 'agent_support_payment.png', type:paymentimg.type})
            formData.append('token', tknsts)
            formData.append('amount', paydata.amount)
            formData.append('purpose', 'AGNT_SPT')
            axios.post(`${ServerData.admin}/addPaymentScreenShot/`, formData, {
                headers:{
                    "content-type": "multipart/form-data",
                },
                })
                .then(function (response) {
                    setLoading(false)
                    alert(response.data.message)
                    navigation.goBack()
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                });
        }
    }

    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          quality: 1,
        });
    
        console.log(result);
    
        if (!result.canceled) {
          setPaymentImg(result.assets[0]);
        }
      };

    const captureView = async () => {
        if (viewShotRef.current) {
          try {
            const uri = await viewShotRef.current.capture();
            const text = "Payment upi qr";
            await Sharing.shareAsync(uri, { text });
          } catch (error) {
            console.error('Error capturing and sharing:', error);
          }
        }
      };

    const upistring = `pay?pa=${paydata.upiid}&pn=${paydata.sellername}&tr=877376394&am=${paydata.amount}&cu=INR&mode=00&purpose=00&mc=5399&tn=${paydata.purpose}`
    const qrstring = `upi://${upistring}`


const upop = [
    {
        'id':1,
        'name':'Phone Pe',
        'deeplick':`phonepe://${upistring}`,
        'icon':require('./asset/ppe.png')
    },
    {
        'id':4,
        'name':'UPI',
        'deeplick':`upi://${upistring}`,
        'icon':require('./asset/upi.svg')
    },


]

    const styles = StyleSheet.create({
        mn:{
            margin:10
        },
        optxt:{
            marginTop:10,
            textTransform:'uppercase',
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:10
        },
        icn:{
            width:(width-30)/8,
            height:(width-30)/8,
        },
        iv:{
            flexDirection:'row',
            alignItems:'center',
            marginTop:10
        },
        tchup:{
            marginRight:10,
            backgroundColor:appColor.background,
            shadowOffset: {
                width: 0,
                height: 2,
              },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            // Shadow properties for Android
            elevation: 5,
            padding:4,
            borderRadius:2
        },
        mn2:{
            flex:1,
            backgroundColor:'rgba(0, 0, 0, 0.8)',
            justifyContent:"center",
            alignItems:'center'
        },
        mdv:{
            height:height/1.2,
            width:width,
            backgroundColor:appColor.background
        },
        mttxt:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txheadblack
        }
    })

  return (
    <CustomSafeArea>
        <HeaderComponent title={'UPI Payment'} hidect={true}/>
        <Modal
            animationType='slide'
            transparent={true}
            visible={mvisible}
        >
        <View style={styles.mn2}>
            <TouchableOpacity style={{flex:1}} onPress={() => setMVisible(false)}></TouchableOpacity>
            <View style={styles.mdv}>
                <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:10, borderBottomWidth:1, borderBottomColor:appColor.gray}}>
                    <Text style={{fontFamily:appfont.semibold, color:appColor.txheadblack, fontSize:12}}>Scan Using any UPI APP</Text>
                    <TouchableOpacity onPress={() => setMVisible(false)}>
                        <Icon name='closecircleo' size={20} />
                    </TouchableOpacity>
                </View>
                <ViewShot style={{marginTop:20, justifyContent:'center', alignItems:'center'}} ref={viewShotRef} options={{ format: 'png', quality: 1}}>
                    <QRCode value={qrstring} size={width-100}/>
                    <View>
                        <Text style={{fontFamily:appfont.semibold, fontSize:14, color:appColor.primary, marginTop:10}}>Amount: ₹{paydata.amount}</Text>
                    </View>
                </ViewShot>
                <View style={{marginTop:20}}>
                    <CustomButton title={'Share QR'} wt={width/2} bg={appColor.primary} tc={appColor.background} onpress={() => captureView()}/>
                </View>
            </View>
        </View>

        </Modal>

        <ScrollView style={styles.mn}>
            <Text style={styles.mttxt}>Amount: ₹{paydata.amount}</Text>
            <Text style={styles.mttxt}>Payee: {paydata.sellername}</Text>
            <Text style={styles.optxt}>UPI Options</Text>
            <View style={styles.iv}>
                {
                    upop.map((item) => {
                        return(
                            <TouchableOpacity key={item.id} style={styles.tchup} onPress={() => Linking.openURL(item.deeplick)}>
                                <Image source={item.icon} contentFit='contain' style={styles.icn}/>
                            </TouchableOpacity>
                        )
                    })
                }
                <TouchableOpacity style={styles.tchup} onPress={() => setMVisible(true)}>
                                <Image source={require('./asset/qr.png')} contentFit='contain' style={styles.icn}/>
                            </TouchableOpacity>
            </View>
            <View style={{marginTop:20}}>
            <Text style={[styles.optxt, {marginBottom:10}]}>Payment Instructions</Text>
                <View>
                    {
                        instpoints.map((item) => {
                            return(
                                <View key={item.id} style={{flexDirection:'row', marginBottom:5}}>
                                    <View style={{height:14, width:14, marginRight:10}}>
                                        <Text style={{fontFamily:appfont.semibold, fontSize:12, color:appColor.txheadblack}}>{item.id}.</Text>
                                    </View>
                                    <Text style={{fontFamily:appfont.semibold, fontSize:12, color:appColor.txheadblack, flex:1}}>{item.text}</Text>
                                </View>
                            )
                        })
                    }
                </View>
            </View>

            <View style={{marginTop:10}}>
                <UploadPhotoComponent title={'Payment Screenshot'} onpress={() =>pickImage()} img={paymentimg ? paymentimg : null}/>
            </View>
            <View>
                <CustomButton title={'Confirm Payment'} bg={appColor.primary} tc={appColor.background} mt={20} ai={loading} onpress={() => uploadPaymentData()}/>
            </View>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default UPIPayment